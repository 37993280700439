import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { forkJoin, Subject, interval, Subscription } from "rxjs";
import { progressDetails } from "src/app/feature-modules/student-list/models/progressDetails";
import { School } from "src/app/shared/models/school";
import { Student } from "src/app/shared/models/student-base";
import { ApiDataService } from "src/app/shared/services/api.service";
import { StudentsApiService } from "../student-list/services/students-api.service";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { takeUntil } from "rxjs/operators";
import { AppRouteConstants } from "src/app/shared/constants/app-route.constants";
import { SuccessMessages } from "src/app/shared/constants/utility.constants";
import { StudentInfo } from "src/app/shared/models/studentinfo";
import { Tutor } from "src/app/shared/models/tutor-base";
import { SharedDialogService } from "src/app/shared/services/shared-dialog.service";
import { StorageService } from "src/app/shared/services/storage.service";
import { Lesson } from "../lessons/models/lesson";
import { LessonsApiService } from "../lessons/services/lessons-api.service";
import { Reason } from "../student-list/models/reason";
import { StudentUtility } from "../student-list/services/student-utility-services";
import { StudentConstants } from "../student-list/constants/student.constants";

declare var ConfettiGenerator: any;
@Component({
  selector: "app-tutor-dashboard",
  templateUrl: "./tutor-dashboard.component.html",
  styleUrls: ["./tutor-dashboard.component.css"],
})
export class TutorDashboardComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  loading = true;
  lessonControl: FormControl = new FormControl();
  tutorControl: FormControl = new FormControl();
  reasonControl: FormControl = new FormControl();
  studentControl: FormControl = new FormControl();
  schoolControl: FormControl = new FormControl();
  selectedSchool = -1;
  selectedStudent = -1;
  selectedLesson1 = -1;
  schoolList: School[] = [];
  schoolOptions: School[] = [];
  studentList: Student[] = [];
  filteredStudentList: Student[] = [];
  gradeId: number = -1;
  lessons: Lesson[] = [];
  selectedLesson: any = {};
  tutors: Tutor[] = [];
  tutorOptions: Tutor[] = [];
  lessonOptions: Lesson[] = [];
  reasons: Reason[] = [];
  updateStudentProgressGroup: FormGroup;
  nonDisableControlNames = [
    "student",
    "recorded_date",
    "volunteer",
    "lesson",
    "control_group",
    "reason",
  ];
  student: any;
  studentId = -1;
  tutorId: number;
  lessonId: number;
  lessonIdCopy: number;
  wordMissedFirst: number[] = [];
  wordMissedSecond: number[] = [];
  totalWordLearnt = 0;
  progressDetails: progressDetails;
  edit: boolean;
  absentValue: string[] = ["Yes", "No"];
  beginningEngagementEnabled = true;
  endingEngagementEnabled = true;
  sessionId: any;
  wasStudentAbsent = false;
  maxDate: Date;
  wordToken = false;
  studentInfo: StudentInfo = {
    school: -1,
    isActive: 1,
    grade: -1,
    searchTerm: "",
    limit: 50,
    offset: 0,
    controlGroup: 0,
  };
  loggedInUserRoleId: number;
  isLessonSelected = false;
  selectedReasonForAbsence = -1;
  studentListLoadingInProgress = false;
  lessonListLoadingInProgress = false;
  miroBoardLink: string = "";
  isLoading = false;
  lessonsLoaded = false;
  userEmail = "";
  hasInvited: boolean = false;
  inviteSubscription: Subscription | undefined;

  private ngUnsubscribe = new Subject();
  constructor(
    private apiService: ApiDataService,
    private studentApiService: StudentsApiService,
    private lessonsApiService: LessonsApiService,
    private toastrService: ToastrService,
    public _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private sharedDialogService: SharedDialogService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.initializeForm();
  }

  ngOnInit() {
    if (this.router.url.search(AppRouteConstants.TUTOR_UPDATE_PROGRESS) > 0) {
      localStorage.setItem(
        "previousURL",
        AppRouteConstants.TUTOR_UPDATE_PROGRESS
      );
    }
    this.tutorId = Number(StorageService.getUserId());
    this.getUserInfo(() => {
      this.getSchools(this.tutorId);
      this.inviteTeamUserIfNeeded();
    });
    this.maxDate = new Date();
    this.getConfetti();

    this.studentControl.valueChanges.subscribe((value) => {
      this.filteredStudentList = StudentUtility.filterOptions(
        this.studentList,
        value,
        ["UserName"]
      );
    });

    this.schoolControl.valueChanges.subscribe((value) => {
      this.schoolOptions = StudentUtility.filterOptions(
        this.schoolList,
        value,
        ["school_name"]
      );
    });
  }

  inviteTeamUserIfNeeded() {
    if (this.userEmail && !this.hasInvited) {
      // Start inviting users only if userEmail is set and hasInvited is false
      this.startInvitingUsers();
    }
  }

  startInvitingUsers() {
    if (this.inviteSubscription && !this.inviteSubscription.closed) {
      this.inviteSubscription.unsubscribe();
    }

    this.inviteSubscription = interval(1000).subscribe(() => {
      this.inviteTeamUser()
        .then((status) => {
          if (status === 200) {
            console.log("Invitation successful.");
            this.inviteSubscription?.unsubscribe();
          } else {
            console.log("Retrying invitation...");
          }
        })
        .catch((error) => {
          if (error && error.status === 404) {
            console.log("User not found. Retrying...");
          } else if (error && error.status === 409) {
            console.log("User already exists. Stopping invitation.");
            this.inviteSubscription?.unsubscribe(); // Stop inviting users for 409
          } else {
            console.error("Error inviting user:", error);
          }
        });
    });
  }

  async inviteTeamUser(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.apiService.inviteTeamUserToMiro(this.userEmail).subscribe(
        (response) => {
          if (response.status === 200) {
            this.hasInvited = true;
          }
          resolve(response.status);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getConfetti() {

    let passageData: FormArray = this.updateStudentProgressGroup.get("passages") as FormArray;
    let lastPassage = passageData.at(passageData.length - 1) as FormGroup;
    let totalWordsLearnedControl = lastPassage.get("total_words_learned");

    totalWordsLearnedControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((words) => {
        var canvas1Settings = {
          target: "canvas1",
          max: 100,
          props: ["square", "triangle", "line"],
          colors: [
            [165, 104, 246],
            [230, 61, 135],
            [0, 199, 228],
            [253, 214, 126],
          ],
          clock: 3,
        };
        var canvas1 = new ConfettiGenerator(canvas1Settings);
        canvas1.render();
        if (words > 0) {
          document
            .getElementsByClassName("hug-tutor-attempts__confitti")[0]
            .classList.remove("hide");
        } else {
          document
            .getElementsByClassName("hug-tutor-attempts__confitti")[0]
            .classList.add("hide");
        }
      });
  }

  getUserInfo(callback: any) {
    this.loading = true;
    this.subscription = this.apiService.getUserInfo().subscribe((res) => {
      if (res) {
        this.loggedInUserRoleId = res.role.id;
        this.userEmail = res.email;
        if (callback) {
          callback();
        }
      }
    });
  }

  maskLastName(fullName: string): string {
    const nameParts = fullName.split(" ");
    if (nameParts.length >= 2) {
      let lastName = nameParts[nameParts.length - 1];
      // Only show the first two letters of the last name
      const visibleLetters = Math.min(2, lastName.length);
      const maskedLastName = `${lastName.slice(0, visibleLetters)}${"*".repeat(
        Math.max(0, lastName.length - visibleLetters)
      )}`;
      nameParts[nameParts.length - 1] = maskedLastName;
      return nameParts.join(" ");
    } else {
      return fullName;
    }
  }

  setMoodValue(moodValue: number, controlName: string) {
    this.updateStudentProgressGroup.get(controlName).setValue(moodValue);
  }

  checkBoxChanges(event: any) {
    this.wasStudentAbsent = event.value;
    this.resetSelectedEngagement();
  }

  getSchools(tutorId: number) {
    if (this.loggedInUserRoleId === 1 || this.loggedInUserRoleId === 2) {
      this.subscription = this.apiService.getSchoolList().subscribe((res) => {
        this.sort(res);
      });
    } else {
      this.apiService
        .tutorsAssociatedWithSchool(tutorId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.sort(res);
        });
    }
  }

  sort(res: any) {
    if (res) {
      this.schoolList = res;
      this.schoolOptions = res;
      this.schoolList = this.schoolList.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
      this.loading = false;
    }
  }

  getSelectedStudentDetails() {
    this.apiService
      .getStudent(this.studentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res && res.gradeIddd !== undefined) {
          this.student = res;
          this.getData(this.edit);
        }
      });
  }

  getData(edit: boolean) {
    this.lessonListLoadingInProgress = true;
    const lessonsRes = edit
      ? this.lessonsApiService.getLessonNames()
      : this.studentApiService.getAvailableLessons(
        this.selectedStudent,
        this.gradeId
      );
    const tutorsRes = this.apiService.getVolunteerNames();
    const reasonRes = this.apiService.getAllReason();
    forkJoin([lessonsRes, tutorsRes, reasonRes])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((joinedRes) => {
        if (joinedRes && joinedRes.length) {
          this.lessons =
            joinedRes[0] && joinedRes[0].length ? joinedRes[0] : [];
          this.lessonOptions = this.lessons;
          this.tutors = joinedRes[1] && joinedRes[1].length ? joinedRes[1] : [];
          this.tutorOptions = this.tutors;
          this.reasons =
            joinedRes[2] && joinedRes[2].length ? joinedRes[2] : [];
          if (edit) {
            this.setSelectedLesson(this.progressDetails.lesson_id);
          }
        }
        this.lessonListLoadingInProgress = false;
      });
  }
  wordsValidation(value: number, i: number) {
    this.totalWordLearnt = this.wordMissedFirst[i] - this.wordMissedSecond[i];
  }

  wordsMissed(passage, i) {
    let first = passage.controls.words_missed_first.value ?? 0;
    let second = passage.controls.words_missed_second.value ?? 0;
    passage.controls.total_words_learned.setValue(first - second);
    this.updateStudentProgressGroup.updateValueAndValidity();
  }

  wordsMissedFirst(event: number, passage: any, i: number) {
    this.getConfetti()
    this.wordMissedFirst[i] = Number(event);
    this.wordsValidation(event, i);
    this.wordsMissed(passage, i)

  }
  wordsMissedSecond(event: number, passage, i) {
    this.wordMissedSecond[i] = Number(event);
    this.wordsValidation(event, i);
    this.wordsMissed(passage, i)
  }
  wordsLearnt(event: any, i: number) {
    if (Number(event) > this.selectedLesson?.passages[i].word_count) {
      this.toastrService.error(
        "Total words learnt is greater than the total number of words"
      );
    }
    this.cdr.detectChanges();
  }

  getPassageGroupArray() {

    let passage = this.updateStudentProgressGroup.get("passages") as FormArray
    return passage.controls as FormGroup[]
  }
  createpassageFormGroup(selectedLesson: any, passage: any) {
    return this.fb.group({
      words_missed_first: new FormControl({ value: 0, disabled: selectedLesson.id ? false : true }, [
        Validators.required,
      ]),
      words_missed_second: new FormControl({ value: 0, disabled: selectedLesson.id ? false : true }, [
        Validators.required,
      ]),
      total_words_learned: new FormControl({ value: 0, disabled: selectedLesson.id ? false : true }, [
        Validators.required,
      ]),
      second_read_complete: new FormControl({ value: "", disabled: selectedLesson.id ? false : true }, [
        Validators.required,
      ]),

      passage_id: new FormControl(passage.passage_id || ''),
      passage_name: new FormControl(passage.passage_name || ''),
      word_count: new FormControl(passage.word_count || ''),


    });

  }


  setSelectedLesson(lessnId: number) {
    try {
      this.isLoading = true;
      this.getMiroBoard(
        this.selectedSchool,
        this.selectedStudent,
        this.selectedLesson1,
        this.tutorId
      );
      this.miroBoardLink = "";
      this.lessonIdCopy = lessnId;


      this.selectedLesson = this.lessons.find((les) => les?.id === lessnId);
      this.isLessonSelected = lessnId === -1 ? false : true;

      let passageData: any = this.updateStudentProgressGroup.get("passages") as FormArray
      while (passageData.length !== 0) {
        passageData.removeAt(0);
      }

      this.selectedLesson.passages.forEach((passage, index) => {
        passageData.push(this.createpassageFormGroup(this.selectedLesson, passage));
      });
      this.resetSelectedEngagement();
      this.resetWordsMissed();

    } catch (error) {
      console.log(error);
    }
  }
  getMiroBoard(
    schoolId?: number,
    studentId?: number,
    lessonId?: number,
    tutorId?: number
  ) {
    this.apiService
      .setBoardData(schoolId, studentId, lessonId, tutorId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res?.boardId) {
          this.miroBoardLink = res?.boardId;
          this.isLoading = false;
        } else {
          this.toastrService.error(res);
          this.isLoading = false;
        }
      });
  }
  logMiroBoardLink() {
    window.open(this.miroBoardLink, "_blank");
  }
  resetWordsMissed() {
    this.updateStudentProgressGroup.controls["words_missed_first"].setValue(0);
    this.updateStudentProgressGroup.controls["words_missed_second"].setValue(0);
    this.updateStudentProgressGroup.controls["total_words_learned"].setValue(0);
  }
  resetSelectedEngagement() {
    this.updateStudentProgressGroup.controls["mood_first"].setValue("");
    this.updateStudentProgressGroup.controls["mood_second"].setValue("");
    this.updateStudentProgressGroup.controls["volunteer_mood"].setValue("");
  }

  getAssociatedStudents() {
    this.studentInfo.school = this.selectedSchool;
    this.apiService
      .getAllAssociatedStudents(this.tutorId, this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.studentList = res.student;
          this.studentListLoadingInProgress = false;
          this.filteredStudentList = res.student;
        }
      });
  }
  onSchoolChange(event: any) {
    this.selectedSchool = event.value;
    this.studentList = [];
    this.lessonOptions = [];
    this.isLessonSelected = false;
    this.studentId = this.selectedStudent = -1;
    this.selectedLesson1 = -1;
    this.miroBoardLink = "";
    this.resetSelectedEngagement();
    this.studentListLoadingInProgress = true;
    if (this.loggedInUserRoleId === StudentConstants.TUTOR_ROLE_ID) {
      this.getAssociatedStudents();
    } else {
      this.getStudentList();
    }
  }
  getStudentList() {
    this.studentInfo.school = this.selectedSchool;
    this.apiService
      .getAllStudents(this.studentInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.studentList = res.student;
        this.filteredStudentList = res.student;
        this.studentListLoadingInProgress = false;
      });
  }
  async onStudentChange(event: any) {
    this.lessonOptions = [];
    this.selectedLesson1 = -1;
    this.studentId = this.selectedStudent = event.value;
    try {
      await this.setGradeIds(); // Wait for setGradeIds to complete
      this.lessonsLoaded = false;
    } catch (error) {
      console.error("Error setting grade IDs:", error);
      // Handle the error appropriately
    }
    this.selectedLesson = {};
    this.isLessonSelected = false;
    this.resetSelectedEngagement();
    this.getSelectedStudentDetails();
    if (!isNaN(this.lessonId)) {
      this.getProgressDetails({
        studentId: this.studentId,
        lessonId: this.lessonId,
      });
      this.edit = true;
    }

    this.lessonControl.valueChanges.subscribe((value) => {
      this.lessonOptions = StudentUtility.filterOptions(this.lessons, value, [
        "lesson_name",
      ]);
    });
    if (this.selectedLesson1 != -1) {
      this.setSelectedLesson(this.selectedLesson1);
    }
    this.getData(this.edit);
  }
  setGradeIds(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .getStudent(this.studentId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res) => {
            if (res && res.gradeIddd !== undefined) {
              this.gradeId = res.gradeIddd;
              resolve(); // Resolve the promise when the API call is successful
            } else {
              reject(new Error("Failed to set grade ID")); // Reject the promise if the API call fails
            }
          },
          (error) => {
            console.error("Error in setGradeIds:", error);
            reject(error); // Reject the promise if there's an error
          }
        );
    });
  }

  async getProgressDetails(progress: any) {
    this.loading = true;
    this.apiService
      .getProgressByLesson(progress)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.progressDetails = res;
          this.setForm();
        }
      });
    this.loading = false;
  }

  editParamsSubscribe() {
    this.route.params.subscribe((params) => {
      this.studentId = +params.id;
      this.lessonId = +params.lessonId;
    });
  }

  initializeForm() {
    this.updateStudentProgressGroup = new FormGroup({
      student: new FormControl(this.studentId),
      lesson: new FormControl("", Validators.required),
      volunteer: new FormControl(""),
      recorded_date: new FormControl("", Validators.required),
      reason: new FormControl(""),
      words_missed_first: new FormControl({ value: "0", disabled: true }),
      mood_first: new FormControl("", Validators.required),
      words_missed_second: new FormControl({ value: "0", disabled: true }),
      mood_second: new FormControl("", Validators.required),
      total_words_learned: new FormControl({ value: "0", disabled: true }),
      volunteer_mood: new FormControl("", Validators.required),
      comments: new FormControl(""),
      control_group: new FormControl(false),
      be_the_diff_complete: new FormControl(""),
      passages: this.fb.array(this.createDefaultPassageGroups())
    });
  }

  createDefaultPassageGroups() {
    const defaultPassageGroups = [];
    for (let i = 0; i < 2; i++) {
      defaultPassageGroups.push(this.createDisabledPassageFormGroup());
    }
    return defaultPassageGroups;
  }

  createDisabledPassageFormGroup() {
    return this.fb.group({
      words_missed_first: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      words_missed_second: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      total_words_learned: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      second_read_complete: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      passage_id: new FormControl({ value: '', disabled: true }),
      passage_name: new FormControl({ value: '', disabled: true }),
      word_count: new FormControl({ value: '', disabled: true }),
    });
  }

  setForm() {
    if (!isNaN(this.lessonId)) {
      this.updateStudentProgressGroup.patchValue({
        student: this.studentId,
        recorded_date: this.progressDetails.date,
        volunteer: this.progressDetails.volunteer_id,
        lesson: this.progressDetails.lesson_id,
        reason: "",
        volunteer_mood: this.progressDetails.volunteer_mood,
        words_missed_first: this.progressDetails.words_missed_first,
        mood_first: this.progressDetails.student_mood_first,
        words_missed_second: this.progressDetails.words_missed_second,
        mood_second: this.progressDetails.student_mood_second,
        total_words_learned: this.progressDetails.total_words_learned,
        comments: this.progressDetails.comments,
        be_the_diff_complete: this.progressDetails.be_the_diff_complete,
        control_group: false,
        total_words: 0,
      });
    }
    this.updateStudentProgressGroup.controls['passages'] = this.fb.array(
      this.createPassageGroups(this.progressDetails.passages)
    );
    this.sessionId = this.progressDetails.id;
  }

  createPassageGroups(passages) {
    return passages.map((passage) => {
      return this.fb.group({
        words_missed_first: new FormControl(passage.words_missed_first, [Validators.required]),
        words_missed_second: new FormControl(passage.words_missed_second, [Validators.required]),
        total_words_learned: new FormControl(passage.words_missed_first - passage.words_missed_second, [Validators.required]),
        second_read_complete: new FormControl(passage.second_read_complete, [Validators.required]),
        passage_id: new FormControl(passage.passage_id),
        passage_name: new FormControl(passage.passage_name),
        word_count: new FormControl(passage.word_count),
      });
    });
  }
  setSelectedReasoForAbsence(event) {
    this.selectedReasonForAbsence = event;
  }

  onTerribleClick() {
    if (this.isLessonSelected) {
    this.sharedDialogService.TutorEngagementAction();
    }
  }

  updateProgress(progress: any) {
    progress.volunteer = this.tutorId;
    progress.words_missed_first = this.wordMissedFirst;
    progress.words_missed_second = this.wordMissedSecond;
    progress.total_words_learned = this.totalWordLearnt;
    progress.reason = this.selectedReasonForAbsence;
    this.loading = true;
    if (!progress.control_group) {
      progress.total_words = this.selectedLesson?.word_count;
    }
    progress.student = this.selectedStudent;
    if (!this.edit) {
      if (!this.wasStudentAbsent) {
        this.getUpdateNewProgress(progress);
      } else {
        this.getUpdateAbsence(progress);
      }
    }
    this.loading = false;
  }
  getUpdateNewProgress(progress) {
    if (this.updateStudentProgressGroup.valid) {
      this.apiService.updateNewProgress(progress).subscribe(
        (res) => {
          if (res) {
            this.sharedDialogService.UpdateProgressAction(
              progress.passages
            );
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      this.toastrService.error(
        "Please fill all the required fields before submitting."
      );
      this.loading = false;
    }
  }
  getUpdateAbsence(progress) {
    if (
      this.reasonControl.value &&
      progress.lesson !== -1 &&
      progress.recorded_date &&
      this.selectedSchool !== -1
    ) {
      this.apiService.updateNewProgress(progress).subscribe(
        (res) => {
          if (res) {
            this.toastrService.success(SuccessMessages.UPDATE_ABSENCE);
            location.reload();
          } else {
            this.toastrService.error(
              "Absence for the lesson was already marked for the respective student"
            );
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    } else {
      this.toastrService.error("Please fill all the required fields");
      this.loading = false;
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
